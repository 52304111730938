import React from 'react'
import './Projects.scss'

const Projects = ({ theme }) => {
  return (
    <div className="projects">
      <h1>Projects</h1>
      <div className="project-container">
        <img className={theme === 'light' ? "project-img" : "project-img-dark"} src=
          "https://i.imgur.com/pyzTmPI.jpg" alt="sour pomodoro screencap" />
        <article className={theme === 'light' ? "project-text" : "project-text-dark"}>
          <h2 className="project-name">Sour Pomodoro</h2>
          <div className="project-links">
            <a href="https://kbergsund.github.io/sour-pomodoro/" target="_blank" rel="noreferrer" className={theme === "light" ? "site-link" : "site-link-dark"}>Deployed Site </a>|
            <a href="https://github.com/reganlosey/sour-pomodoro" target="_blank" rel="noreferrer" className={theme === "light" ? "site-link" : "site-link-dark"}> Github Repo</a>
          </div>
          <p>Everybody enjoys a good movie, that's a given.
            But what about those so-bad-it's-good movies? Sour Pomodoro (definitely NOT Rotten Tomatoes) makes it effortless to find the painful-to-read synopses, the cliché promo photos, and the embarrassing ratings of the worst movies from the latest years.
          </p>
          <p>
            Sour Pomodoro was a paired project completed over 2 weeks by another developer and I to display our accumulated knowledge attained through Turing's rigorous Front End Engineering program.
          </p>
          <p className="tech">Tech Stack: React, React Router, SCSS, Cypress</p>
        </article>
      </div>
      <div className="project-container">
        <img className={theme === 'light' ? "project-img" : "project-img-dark"} src="https://i.imgur.com/MdLub0Y.jpg" alt="blends with benefits homepage" />
        <article className={theme === 'light' ? "project-text" : "project-text-dark"}>
          <h2 className="project-name">Blends With Benefits</h2>
          <div className="project-links">
            <a href="https://blendswithbenefits.netlify.app/" target="_blank" rel="noreferrer" className={theme === 'light' ? "site-link" : "site-link-dark"}>Deployed Site </a>|
            <a href="https://github.com/reganlosey/blends-with-benefits" target="_blank" rel="noreferrer" className={theme === 'light' ? "site-link" : "site-link-dark"}> Github Repo</a>
          </div>
          <p>Hey there, hot stuff. The daily grind can be exhausting, but Blends With Benefits is here to help you take life one cup at a time. So take a deep breath, embrace your inner tranquili-tea, and enjoy the shop that was brewed espresso-ly for you with a whole latté love.</p>
          <p>
            This was a personal solo project and the first full-stack application I've built on my own. I wanted to keep up my momentum after graduating from Turing School Of Software and Design and this was an idea that I had been kicking around for a while. I'm also a hopeless coffee addict and pun fanatic (ask me about my punny coffee tattoo!) which was the inspiration for this project. I had a ton of fun working on this project and getting to interact with my own API and get some more practice with using Redux was really cool.
          </p>
          <p className="tech">Tech Stack: React, Redux, SASS, Cypress</p>
        </article>
      </div>
      <div className="project-container">
        <img className={theme === 'light' ? "project-img" : "project-img-dark"} src="https://i.imgur.com/ImOFvLW.jpg" alt="brewed to serve screencap" />
        <article className={theme === 'light' ? "project-text" : "project-text-dark"}>
          <h2 className="project-name">Brewed To Serve</h2>
          <div className="project-links">
            <a href="https://brewed-to-serve.onrender.com/" target="_blank" rel="noreferrer" className={theme === 'light' ? "site-link" : "site-link-dark"}>Deployed Site </a>|
            <a href="https://github.com/reganlosey/brewed_to_serve" target="_blank" rel="noreferrer" className={theme === 'light' ? "site-link" : "site-link-dark"}> Github Repo</a>
          </div>
          <p> And now for something slightly different. Brewed To Serve is an API built using Node.js and Express.js to host an array of coffee and tea data. A few months ago, I had an idea for a project that would need to display information about different sorts of brewed beverages, but I couldn't find any public APIs online that had what I needed. So, I decided to brew one up myself. This was a really fun personal project and a really good learning experience. I'm happy to have gotten some good insight not only into how the backend side of things work, but how the frontend applications I've made interact with APIs.
          </p>
          <p className="tech">Tech Stack: Node.js, Express.js</p>
        </article>
      </div>
      <div className="project-container">
        <img className={theme === 'light' ? "project-img" : "project-img-dark"} src="https://i.imgur.com/HB3K6k6.png" alt="currency converter screencap" />
        <article className={theme === 'light' ? "project-text" : "project-text-dark"}>
          <h2 className="project-name">Currency Converter</h2>
          <div className="project-links">
            <a href="https://simple-ts-converter.netlify.app/" target="_blank" rel="noreferrer" className={theme === 'light' ? "site-link" : "site-link-dark"}>Deployed Site </a>|
            <a href="https://github.com/reganlosey/currency-converter" target="_blank" rel="noreferrer" className={theme === 'light' ? "site-link" : "site-link-dark"}> Github Repo</a>
          </div>
          <p> This was a super fun quick project I used as an opportunity to teach myself some Typescript. I found a free API that provided currency conversion and jumped into whipping up a simple app that I could put some Typescript into. Even though I didn't dive too terribly deep into its capabilities, I'm excited to start using Typescript in place of Javascript when I can. Did I mention the headache Typescript saved me? The hype is real, I'm a believer.
          </p>
          <p className="tech">Tech Stack: Typescript, React, SASS</p>
        </article>
      </div>
      <div className="project-container">
        <img className={theme === 'light' ? "project-img" : "project-img-dark"} src="https://i.imgur.com/ZKbgokt.jpg" alt="under a tack screencap" />
        <article className={theme === 'light' ? "project-text" : "project-text-dark"}>
          <h2 className="project-name">Under-A-Tack!</h2>
          <div className="project-links">
            <a href="https://under-a-tack.netlify.app/" target="_blank" rel="noreferrer" className={theme === 'light' ? "site-link" : "site-link-dark"}>Deployed Site </a>|
            <a href="https://github.com/reganlosey/under-a-tack" target="_blank" rel="noreferrer" className={theme === 'light' ? "site-link" : "site-link-dark"}> Github Repo(UI) </a>|
            <a href="https://github.com/reganlosey/under-a-tack-API" target="_blank" rel="noreferrer" className={theme === 'light' ? "site-link" : "site-link-dark"}> Github Repo(API)</a>
          </div>
          <p>Love art? Love posters? Love...buying things? Look no further!
            Under-A-Tack (get it?) is a commerce website for art lovers looking for posters that fill their need for unique and specific tastes. Purchase art pieces from a collection of different genres as posters and build your plan of A-Tack!
          </p>
          <p>
            This project was an opportunity taken to learn and implement Express.js and PostgreSQL to create an API that held the data being displayed on the UI.
          </p>
          <p className="tech">Tech Stack: React, React Router, Express.js, PostgreSQL, SCSS, Cypress</p>
        </article>
      </div>
      <div className="project-container">
        <img className={theme === 'light' ? "project-img" : "project-img-dark"} src="https://i.imgur.com/DEKTpfy.png" alt="salts and malts screencap" />
        <article className={theme === 'light' ? "project-text" : "project-text-dark"}>
          <h2 className="project-name">Salts and Malts</h2>
          <div className="project-links">
            <a href="https://reganlosey.github.io/salts-and-malts/#/" target="_blank" rel="noreferrer" className={theme === 'light' ? "site-link" : "site-link-dark"}>Deployed Site </a>|
            <a href="https://github.com/reganlosey/salts-and-malts" target="_blank" rel="noreferrer" className={theme === 'light' ? "site-link" : "site-link-dark"}> Github Repo</a>
          </div>
          <p>Want to learn more about beer? Salts and Malts is a beer-food pairing app that rises in the yeast and sets in the waist (please, hold your applause).
            With this application, you can browse a variety of beer types, the foods they pair with, the prominent hops, ABV, and IBU.
            Plus you can finally keep up with the craft beer beer scene and nail that "What does IBU stand for?" trivia question.
          </p>
          <p>
            This project was completed over 4 days and was an opportunity to showcase my ability to quickly bring an app to production using React and a 3rd party API.
          </p>
          <p className="tech">Tech Stack: React, React Router, SCSS, Cypress</p>
        </article>
      </div>
    </div>
  )


}

export default Projects