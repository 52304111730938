import './About.scss'
import React from 'react';
import { FaGitSquare, FaReact, FaNodeJs, FaGithub } from 'react-icons/fa'
import { SiChai, SiMocha, SiSass, SiExpress, SiCypress, SiPostman, SiTypescript } from 'react-icons/si'
import { FiFigma } from 'react-icons/fi'
import { DiCss3 } from 'react-icons/di'
import { AiFillHtml5 } from 'react-icons/ai'
import { IoLogoJavascript } from 'react-icons/io'

const About = ({ theme }) => {
  return (
    <div className="about">
      <h1>About Me</h1>
      <article className="intro-text">
        <p> As a developer, I'm really excited to be able to combine my love of tech and passion for art to have the opportunity to make some really cool stuff, and make some really cool stuff with other really cool people. I'm a sucker for a good puzzle and I love a good art project, so software development is a perfect fit. My main drive for improving my skills as a developer is to make the world a better place to live. I know that's a big overarching statement but I'm always looking for a way to leverage my skills to do whatever I can to do good by the world. All in all, I've been really lucky to find a career path that empowers me to challenge myself and continue to grow, both as a human and as a developer.
        </p>
      </article>
      <article className={theme === 'light' ? "bio-text" : 'bio-text-dark'}>
        <p> When I'm not coding or studying, I love to be outside, rain or shine. I'm currently living the sweet mountain town life in Flagstaff, Arizona.
          I'm also a huge art fanatic and I'm currently on a mission to visit one art museum in every major city in the United States. And also drink one black coffee from the highest rated coffee shop in that city, with hopes that one day I'll be able to definitively say that I've had the country's best cup of coffee.
          My biggest hobby is Brazilian Jiu Jitsu, which I've been training in for almost five years now. I've been training combat sports in some form or another for about 10 years and Jiu Jitsu is my favorite so far.
        </p>
        <div className="skills-container">
          <h5 className="skills-top-text">A peek into my toolbox:</h5>
          <div className={theme === 'light' ? "langs-container" : "langs-container-dark"}>
            Frontend
            <div className="fe-container">
              React <FaReact className="fe-icon" /> Javascript <IoLogoJavascript className="fe-icon" /> Typescript <SiTypescript className="fe-icon"/>   HTML5  <AiFillHtml5 className="fe-icon" />   CSS3  <DiCss3 className="fe-icon" />   SASS  <SiSass className="fe-icon" />
            </div>
            Backend
            <div className="be-container">Express.js  <SiExpress className="be-icon" /> Node.js  <FaNodeJs className="be-icon" /> </div>
            Testing
            <div className="test-container">Cypress  <SiCypress className="test-icon" />Mocha  <SiMocha className="test-icon" /> Chai  <SiChai className="test-icon" /> </div>
            Other Tools
            <div className="tools-container">Git <FaGitSquare className="tool-icon" /> GitHub <FaGithub className="tool-icon" /> Figma <FiFigma className="tool-icon" /> Postman <SiPostman className="tool-icon" /></div>
          </div>
        </div>
      </article>
    </div>
  )

}


export default About;