import './ErrorPage.scss'
import { Link } from 'react-router-dom';


const ErrorPage = () => {
  return (
    <div className="error-page">
      <h1>Looks like you took a wrong turn.</h1>
      <h2>Click this button to get back to my super sweet site.</h2>
      <Link to="/"><button className="return-btn">Home</button></Link>
    </div>
  )


}

export default ErrorPage;