import React from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import './Header.scss'

const Header = ({ theme }) => {

  return (
    <section className="header">
      <div className="links-container">
        <NavLink className={theme === 'light' ? 'link' : 'link-dark'} to={"/"}>home</NavLink>
        <NavLink className={theme === 'light' ? 'link' : 'link-dark'} to={"/projects"}>projects</NavLink>
        <NavLink className={theme === 'light' ? 'link' : 'link-dark'} to={"/contact"}>contact</NavLink>
        {/* <NavLink className={theme === 'light' ? 'link' : 'link-dark'} to={"/chat"}>coffee with me</NavLink> */}
        <NavLink className={theme === 'light' ? 'link' : 'link-dark'} to={"/about"}>about me</NavLink>
      </div>
    </section>
  )


}


export default Header;