import React from 'react'
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import Resume from '../../assets/regan_losey_resume.pdf';


import './Homepage.scss';



const Homepage = ({ theme }) => {
  return (
    <>
      <div className="homepage">
        <div className="info-container">
          <section className="intro">
            <p className="regan">Hi, I'm Regan</p>
            <p className="pronounce">(that's [rēg-in])</p>
            <p className="brief">I'm a Front End Web Developer.</p>
            <p className="brief brief--other">But I do other stuff too. Like Brazilian Jiu Jitsu and visit art museums.</p>
          </section>
          <div className="icon-container">

            <a href={Resume} target="_blank" rel="noreferrer"><button className={theme === 'light' ? "resume" : "resume-dark"} style={{ fontFamily: "Raleway" }}>Resumé</button></a>
            <a href="https://github.com/reganlosey" target="_blank" rel="noreferrer" >
              <FaGithub className={theme === 'light' ? "icon gh" : "icon-dark gh"} />
            </a>
            <a href="https://www.linkedin.com/in/regan-losey/" target="_blank" rel="noreferrer">
              <FaLinkedin className={theme === 'light' ? "icon li" : "icon-dark li"} />
            </a>
          </div>
        </div>
      </div>
    </>
  )


}

export default Homepage