import './Blog.scss'


const Blog = () => {
  return (
    <div className="blog">
      <h1>Okay this is the page where I just talk about myself.</h1>
      <article className="feb-22">Exciting news today!</article>
    </div>
  )
}


export default Blog;