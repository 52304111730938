import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
.App {
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  transition: background 0.2s ease-in, color 0.2s ease-in;
}
`;

export const lightTheme = {
  body: '#ffecd1',
  text: '#121620'
};
export const darkTheme = {
  body: '#121620',
  text: '#ffecd1'
};