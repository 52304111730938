import './Chat.scss';

const Chat = ({ theme }) => {
  return (
    <div className="chat">
      <h1 className="chat-header">Have some coffee with me!</h1>
      <div className="chat-text">
        <p>One thing I really miss about sales is getting to talk to and meet new people almost every day. It was hard to sometimes have a conversation and make a friend when the time I spent with my customers was transactional, but I still managed to meet a lot of really cool people during that time.</p>
        <p>I still try to meet new people, despite the limitations that remote life has. I have a link to my schedule below, so book a time with me and let's hang out on Zoom.</p>
        <a href="https://calendly.com/regan-losey?hide_gdpr_banner=1" target="_blank" rel="noreferrer"><button className={theme === "light" ? "schedule-btn" : "schedule-btn-dark"}>
          Schedule a time here!</button></a>
      </div>
      <div className="faq-text">
        <h2>Frequently Asked Questions:</h2>
        <h3>Q: Don't you feel weird letting anybody, even strangers, schedule a video call with you?</h3>
        <p>A: I have a policy that I will try most things once. I love talking to people, and I think that there's a lot of value in meeting as many people as possible.</p>
        <h3>Q: What are we gonna talk about?</h3>
        <p>A: Whatever you'd like! I'm pretty easy going and can find a conversation in just about anything. I also have a section at the bottom of this page that I occasionally rotate out with conversation pieces. I would prefer to not talk about politics.</p>
        <h3>Q: Do I have to bring coffee?</h3>
        <p>A: Bring whatever you like! Coffee, water, tea, beer, wine, you name it. If you're in the mood to hang out and have a beer, schedule one of my Friday openings and leave a beer-related comment!</p>
      </div>
      <div className="convo-text">
        <h2>Conversation Starter</h2>
        <h3>(Don't feel pressured to bring this to our meeting, I just put this here if you want to hang out without talking about anything in particular.)</h3>
        <h4>Here are 5 perks, choose 2.</h4>
        <ol>
          <li>1. Moneybags: $250 (or 250 of equivalent currency) is given to you everyday, at midnight, without fail. It can be given as cash or deposited into an account of your choice. The amount automatically adjusts for inflation at the start of every year.</li>
          <li>2. Suprahuman: Your physical power increases by 20% across the board. This applies to all aspects of your ability, such as lifting strength, sprinting speed, reaction time, and jump height/length, as well as your stamina. In addition, the 20% affects your current ability, not your initial one (ie if when the change took place you could only bench press 100 pounds & 120 enhanced, by the time you trained to be able to lift 200 pounds, you could lift 240 enhanced).</li>
          <li>3. Prodigy: Your IQ is 'raised' 50 points. This affects your reasoning, problem solving, and overall ability to retain new information.</li>
          <li>4. Looper: Every day, you're given a total of 60 minutes of time that you can reverse. You can choose to use the whole hour at once, or sprinkle it throughout the day. At midnight, your 'balance' goes back to 60, and there's no rollover; so whatever time you don't spend is lost.</li>
          <li>5. Efficient: You no longer require sleep, food/drink, or the need to use the bathroom. You'll run at 'optimal' settings for the rest of your life, with your body automatically adjusting itself as it sees fit (ie if you are overweight, it will burn fat until you are not. if you're training to build muscle, you will do so without gaining fat. etc)</li>
        </ol>

      </div>
    </div>
  )


}


export default Chat;