import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme, GlobalStyles } from './Components/theme';
import terminalBlinker from './assets/terminal-blinker.svg';
import terminalBlinkerLight from './assets/terminal-blinker-invert.svg';
import lightOn from './assets/lightbulb-on.svg';
import lightOff from './assets/lightbulb-off.svg';
import Header from './Components/Header/Header';
import Homepage from './Components/Homepage/Homepage';
import Projects from './Components/Projects/Projects';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import ErrorPage from './Components/ErrorPage/ErrorPage';
import Blog from './Components/Blog/Blog';
import Chat from './Components/Chat/Chat';


import './App.scss';

const App = () => {
  const [theme, setTheme] = useState('light')
  const isDarkTheme = theme === 'dark';


  const toggleTheme = () => {
    setTheme(isDarkTheme ? 'light' : 'dark')
  }


  return (
    <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      <>
        <GlobalStyles />
        <main className="App">
          <Header theme={theme} />
          <div className="top-img-container">
            <img className="blinker" src={theme === "light" ? terminalBlinker : terminalBlinkerLight} onClick={() => alert("Curious eh? The blinker doesn't do anything cool...yet")} alt="terminal cursor"/>
            <img className="bulb" src={theme === 'light' ? lightOff : lightOn}
              onClick={toggleTheme} alt="lightbulb-icon" />
          </div>
          <Routes>
            <Route path="/" element={<Homepage theme={theme}/>} />
            <Route path="*" element={<ErrorPage />} />
            <Route path="/projects" element={<Projects theme={theme} />} />
            <Route path="/about" element={<About theme={theme} />} />
            <Route path="/contact" element={<Contact theme={theme} />} />
            <Route path="/blog" element={<Blog theme={theme} />} />
            <Route path="/chat" element={<Chat theme={theme} />} />
        </Routes>
        </main>
      </>
    </ThemeProvider>
  )
}



export default App;
