import React from 'react'
import { FaLinkedin, FaGithub } from 'react-icons/fa'
import './Contact.scss'

const Contact = ({ theme }) => {
  return (
    <div className="contact">
      <h1>Contact Me</h1>
      <p className="contact-top-text">Have questions about me or my work? Please don't hesistate to reach out!</p>
      <div className="contact-text">
        <p>reganloseydev@gmail.com</p>
      </div>
      <div className="icon-wrapper">
        <a href="https://www.linkedin.com/in/regan-losey/" target="_blank"  rel="noreferrer">
          <FaLinkedin className={theme === 'light' ? 'contact-icon' : "contact-icon-dark"}/>
        </a>
        <a href="https://github.com/reganlosey" target="_blank" rel="noreferrer">
          <FaGithub className={theme === 'light' ? 'contact-icon' : "contact-icon-dark"} />
        </a>
      </div>
    </div>
  )

}

export default Contact;